import { BreadcrumbJsonLd } from 'gatsby-plugin-next-seo';
import React, { useMemo } from 'react';

import { Block } from '@ict-trust/dgt-blocks/src/types/_blocks.types';
import { Grid } from '@material-ui/core';
import { useLocation } from '@reach/router';

import {
  Confirmation,
  FormBlock,
  Payment,
  QuoteOffline,
  QuoteProcessing,
} from '../components';
import { SiteLayout } from '../layouts/site.layout';
import { generateBreadcrumbsList } from '../utils';
import { TemplateProps } from './template.types';

export const Page: React.FC<TemplateProps> = ({ ...templateProps }) => {
  const {
    pageContext: {
      pageId,
      pageTitle,
      pageBlocks = [],
      backgroundColor,
      metaDescription,
    },
  } = templateProps;
  const { pathname } = useLocation();
  const isQuote = pageId === 'quote';

  const breadcrumbs = useMemo(
    () => generateBreadcrumbsList(pathname),
    [pathname],
  );

  return (
    <SiteLayout
      title={pageTitle}
      isQuote={isQuote}
      backgroundColor={backgroundColor}
      metaDescription={metaDescription}
      {...templateProps}
    >
      <BreadcrumbJsonLd itemListElements={breadcrumbs} />
      <Grid>
        {pageBlocks.map((block) => {
          switch (block.type) {
            case 'payment':
              return <Payment key="payment" />;
            case 'confirmation':
              return <Confirmation key="confirmation" />;
            case 'processing':
              return <QuoteProcessing key="processing" />;
            case 'quoteOffline':
              return <QuoteOffline key="offline" />;
            case 'form':
              return (
                <FormBlock key={block.id} block={block} {...templateProps} />
              );
            default:
              console.warn(
                `Could not find handler for block "${(block as Block).type}"`,
              );
              return '';
          }
        })}
      </Grid>
    </SiteLayout>
  );
};

export default Page;
